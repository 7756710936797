import Menu from "./components/Menu";
import HomePage from "./pages/HomePage";
import FlightDetailsPage from "./pages/FlightDetailsPage";
import PublicNoticesPage from "./pages/PublicNoticesPage";
import PublicNoticesDetailsPage from "./pages/PublicNoticesDetailsPage";
import AdminAnnouncementsPage from "./pages/AdminAnnouncementsPage";
import AdminAnnouncementsDetailsPage from "./pages/AdminAnnouncementsDetailsPage";
import TenantLoginPage from "./pages/TenantLoginPage";
import React, { useEffect, useState } from "react";
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  IonLoading,
  IonLabel,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonContent,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import ContactUsPage from "./pages/ContactUsPage";
import ContactUsSuccessPage from "./pages/contact-us/SuccessPage";
import NotFoundPage from "./pages/errors/NotFoundPage";
import "./App.css";
import { getAuthorizeToken } from "./Api";

const App: React.FC = () => {
  const [showLoading, setShowLoading] = useState(true);
  const [isAuthorizedApp, setIsAuthorizedApp] = useState(false);
  const [errorStatus, setErrorStatus] = useState<number | null>(null);
  useEffect(() => {
    // Allow user to navigate on the site once the App is authorized
    getAuthorizeToken(
      (token: any) => {
        if (token != null) {
          setShowLoading(false);
          setIsAuthorizedApp(true);
        }
      },
      (message: string, status: number | null) => {
        setErrorStatus(status);
        console.error(`${message}`);
      }
    );
  }, [setIsAuthorizedApp, setErrorStatus]);

  return (
    <IonApp className={errorStatus && errorStatus > 0 ? "page-error" : ""}>
      {isAuthorizedApp ? (
        <IonReactRouter>
          <IonSplitPane contentId="main" when="xs">
            <IonTabs className="tab-navigation">
              <IonRouterOutlet id="main">
                <Route path="/page/home" component={HomePage} exact />
                <Route
                  path="/page/flight-details"
                  component={FlightDetailsPage}
                  exact
                />
                <Route
                  path="/page/public-notices"
                  component={PublicNoticesPage}
                  exact
                />
                <Route
                  path="/page/public-notices-details"
                  component={PublicNoticesDetailsPage}
                  exact
                />
                <Route
                  path="/page/admin-announcements"
                  component={AdminAnnouncementsPage}
                  exact
                />
                <Route
                  path="/page/admin-announcements-details"
                  component={AdminAnnouncementsDetailsPage}
                  exact
                />
                <Route
                  path="/page/contact-us"
                  component={ContactUsPage}
                  exact
                />
                <Route
                  path="/page/tenant-login"
                  component={TenantLoginPage}
                  exact
                />
                <Route
                  path="/page/contact-us/success"
                  component={ContactUsSuccessPage}
                  exact
                />
                <Redirect from="/" to="/page/Home" exact />
                <Route>
                  <NotFoundPage />
                </Route>
              </IonRouterOutlet>

              <IonTabBar
                className="tab-navigation__bar"
                slot="bottom"
                selected-tab="home"
              >
                <IonTabButton
                  className="tab-navigation__btn"
                  tab="home"
                  href="/page/home"
                  selected
                >
                  <IonIcon
                    className="tab-navigation__icon"
                    src="/assets/tabs/airplane.svg"
                  />
                  <IonLabel className="tab-navigation__label">
                    <span>Flight</span> <span aria-hidden="true">Schedule</span>
                  </IonLabel>
                </IonTabButton>
                <IonTabButton
                  className="tab-navigation__btn"
                  tab="public-notices"
                  href={`/page/public-notices`}
                >
                  <IonIcon
                    className="tab-navigation__icon"
                    src="/assets/tabs/megaphone.svg"
                  />
                  <IonLabel className="tab-navigation__label">
                    <span aria-hidden="true">Public</span> Notices
                  </IonLabel>
                </IonTabButton>
                <IonTabButton
                  className="tab-navigation__btn"
                  tab="contact-us"
                  href={`/page/contact-us`}
                >
                  <IonIcon
                    className="tab-navigation__icon"
                    src="/assets/tabs/at-sign.svg"
                  />
                  <IonLabel className="tab-navigation__label">
                    Contact <span aria-hidden="true">Us</span>
                  </IonLabel>
                </IonTabButton>
                <IonTabButton
                  className="tab-navigation__btn"
                  tab="tenant-login"
                  href={`/page/tenant-login`}
                >
                  <IonIcon
                    className="tab-navigation__icon"
                    src="/assets/tabs/user-login.svg"
                  />
                  <IonLabel className="tab-navigation__label">
                    Tenant <span aria-hidden="true">Login</span>
                  </IonLabel>
                </IonTabButton>
              </IonTabBar>
            </IonTabs>
          </IonSplitPane>
          <Menu />
        </IonReactRouter>
      ) : (
        [
          <React.Fragment key="app-root-ion-content">
            {errorStatus && errorStatus > 0 ? (
              <IonContent className="page-error__details">
                <h1 className="page-error__label">
                  <span>401</span> App is not authorized
                </h1>
              </IonContent>
            ) : (
              <IonContent className="page-error__details">
                <h1 className="page-error__label">Network Error</h1>
              </IonContent>
            )}
          </React.Fragment>,
          <IonLoading
            key="app-root-preloader"
            cssClass="app-preloader"
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Please wait..."}
            duration={5000}
          />,
        ]
      )}
    </IonApp>
  );
};

export default App;
