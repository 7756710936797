import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonActionSheet,
} from "@ionic/react";

import React, { useState } from "react";
import { chevronForwardOutline } from "ionicons/icons";
import "./Menu.css";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: "Logout",
    url: "/page/logout",
    iosIcon: chevronForwardOutline,
    mdIcon: chevronForwardOutline,
  },
  // {
  //   title: "Settings",
  //   url: "/page/settings",
  //   iosIcon: chevronForwardOutline,
  //   mdIcon: chevronForwardOutline,
  // },
];

const Menu: React.FC = () => {
  const [showActionSheet, setShowActionSheet] = useState(false);

  return (
    <React.Fragment>
      <IonMenu className="menu" contentId="main" type="push">
        <IonHeader className="menu__header">
          <IonToolbar className="menu__header-btn">
            <IonMenuButton className="menu__close-btn" slot="start">
              <IonIcon name="close" className="menu__close-btn__icon"></IonIcon>
            </IonMenuButton>
          </IonToolbar>
          <IonToolbar className="menu__title-wrapper">
            <IonTitle className="menu__title">Tenant Name</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList className="menu__list">
            {appPages.map((appPage, index) => {
              return (
                <IonItem
                  key={index}
                  button
                  onClick={() => setShowActionSheet(true)}
                  detail-icon="false"
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>Logout</IonLabel>
                </IonItem>
              );
            })}
          </IonList>
        </IonContent>
      </IonMenu>

      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        cssClass="action-sheet action-sheet--s-1"
        header="Confirm Logout"
        sub-header="Are you sure you want to logout?"
        buttons={[
          {
            text: "Logout",
            role: "destructive",
            cssClass: "action-sheet-btn action-sheet-btn--logout btn--s-2 btn",
            handler: () => {
              console.log("Logout clicked");
            },
          },
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "action-sheet-btn action-sheet-btn--cancel btn--s-3 btn",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
        ]}
      ></IonActionSheet>
    </React.Fragment>
  );
};

export default Menu;
