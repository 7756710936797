import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard, 
  IonCardContent,
  IonLabel,
  IonInput,
  IonList,
  IonItem,
  IonButton,
  IonText,
  IonLoading,
} from "@ionic/react";
import "./TenantLoginPage.css";

const TenantLoginPage: React.FC = () => {
  const history = useHistory();

  const [showLoading, setShowLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const successTimeout: number = 2000;
  const onSubmit = (data: any) => {
    console.log(data);
    setShowLoading(true);
    setUsername(data.username);
    setPassword(data.password);
    setTimeout(() => {
      history.push("/page/contact-us/success");
      setUsername("");
      setPassword("");
    }, successTimeout);
  };

  const validationMessages = {
    username: {
      required: {
        value: true,
        message: "Username is required.",
      },
    },
    password: {
      required: {
        value: true,
        message: "Password is required.",
      },
    },
  };

  return (
    <IonPage className="subpage--tenant-login subpage">
      <PageHeader backButton={true} backButtonHref="/page/home" />

      <IonContent className="page-content">
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
          duration={successTimeout}
        />

        <IonHeader className="page-content__header">
          <IonToolbar className="page-content__toolbar ion-text-center ion-margin-vertical">
            <IonTitle className="subpage__title page-content__title ion-text-center">Tenant Login</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard className="card--page-container">
          <IonCardContent className="card__content">

            <form onSubmit={handleSubmit(onSubmit)}>
              <IonList
                className="tenant-login-form ion-margin-horizontal ion-no-padding"
                lines="none"
              >
                <IonItem className="ion-margin-vertical">
                  <IonLabel className="tenant-login-form__label" position="stacked">
                    Username
                  </IonLabel>
                  <IonInput
                    className={
                      errors.username
                        ? "tenant-login-form__input tenant-login-form__error-input"
                        : "tenant-login-form__input"
                    }
                    name="username"
                    ref={register(validationMessages.username)}
                    value={username}
                  ></IonInput>
                  {errors.username && (
                    <IonText className="tenant-login-form__error-text">
                      {errors.username.message}
                    </IonText>
                  )}
                </IonItem>

                <IonItem className="ion-margin-vertical">
                  <IonLabel className="tenant-login-form__label" position="stacked">
                    Password
                  </IonLabel>
                  <IonInput
                    className={
                      errors.password
                        ? "tenant-login-form__input tenant-login-form__error-input"
                        : "tenant-login-form__input"
                    }
                    type="password"
                    name="password"
                    // inputmode="password"
                    ref={register(validationMessages.password)}
                    value={password}
                  ></IonInput>
                  {errors.password && (
                    <IonText className="tenant-login-form__error-text">
                      {errors.password.message}
                    </IonText>
                  )}
                </IonItem>

                <IonButton
                  className="tenant-login-form__btn btn--s-2 btn"
                  shape="round"
                  size="large"
                  type="submit"
                  expand="block"
                >
                  Login
                </IonButton>
              </IonList>
            </form>

              <div className="ion-text-center">
                <IonItem href="/page/tenant-login" className="extra-link" detail-icon="false">
                  <IonLabel>
                    Forgot Password?
                  </IonLabel>
                </IonItem>
              </div>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default TenantLoginPage;
