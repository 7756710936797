import React from 'react';
import {IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonCard, IonCardContent} from '@ionic/react';
import './NotFoundPage.css';

const NotFoundPage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
        <IonTitle>
          404 - Page Not Found
        </IonTitle>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">404 - Page Not Found</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard>
          <IonCardContent className="ion-text-center">
            <p>404 - Page Not Found</p>
          </IonCardContent>
        </IonCard>

      </IonContent>

    </IonPage>
  );
};

export default NotFoundPage;
