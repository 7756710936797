import React, { useRef, useState, useEffect } from "react";
import PageHeader from "../components/PageHeader";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonCardContent,
  IonList,
  IonLoading,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonSlides,
  IonSlide,
} from "@ionic/react";
import moment from "moment";
import _ from "lodash";
// import { OneSignal } from "@ionic-native/onesignal";

import "./HomePage.css";
import FlightScheduleGroup from "../components/FlightScheduleGroup";
import { getFlightSchedules } from "../Api";
import { getDayLabels } from "../Utils";

const HomePage: React.FC = () => {
  const [departuresDataGroup, setDeparturesDataGroup] = useState({});
  const [arrivalsDataGroup, setArrivalsDataGroup] = useState({});

  const [loading, setLoading] = useState(true);

  const toSlug = (string: string): string => {
    return string
      .toLowerCase()
      .replace(/[^\w\s-]/g, "") // remove non-word [a-z0-9_], non-whitespace, non-hyphen characters
      .replace(/[\s_-]+/g, "-") // swap any length of whitespace, underscore, hyphen characters with a single -
      .replace(/^-+|-+$/g, ""); // remove leading, trailing -
  };

  useEffect(() => {
    getFlightSchedules().then((response: any) => {
      if (response.data) {
        let rawData = response.data.records;
        rawData.map((item: any) => {
          let [dayLabel, dateLabel] = getDayLabels(response.data.schedule_date);

          item.day_label = dayLabel;
          item.date_label = dateLabel;

          // add can_subscribe field
          item.can_subscribe = false;
          switch (item.remark) {
            case "on time":
            case "final call":
              item.can_subscribe = true;
              break;
            case "cancelled":
            case "departed":
            case "landed":
            case "ferry":
            default:
              item.can_subscribe = false;
              break;
          }

          // Update flight status classes
          item.flight_status_class =
            "flight-schedule__status-" + toSlug(item.remark);

          let atTimestampRe = /^at (\d{1,2}:\d{2}(a|p)$)/g;
          if (atTimestampRe.test(item.remark)) {
            item.can_subscribe = true;
          }
          item.scheduled_time = moment(item.scheduled).format("hh:mm A");
          if (item.leg === "Departure") {
            item.theme = "departures";
            item.from_time = item.scheduled_time;
            item.to_time = "";
          } else {
            item.theme = "arrivals";
            item.from_time = "";
            item.to_time = item.scheduled_time;
          }

          return item;
        });

        let departuresData = _.filter(rawData, {
          leg: "Departure",
        });
        setDeparturesDataGroup(_.groupBy(departuresData, "schedule_date"));

        let arrivalsData = _.filter(rawData, {
          leg: "Arrival",
        });
        setArrivalsDataGroup(_.groupBy(arrivalsData, "schedule_date"));

        setLoading(false);
      }
    });
  }, []);

  // console.log(OneSignal);

  // A ref variable to handle the current slider
  const slider = useRef<HTMLIonSlidesElement>(null);
  // A state value to bind segment value
  const [value, setValue] = useState("0");

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    loop: false,
    pagination: {
      el: null,
    },
  };

  // A function to handle the segment changes
  const handleSegmentChange = (e: any) => {
    setValue(e.detail.value);
    slider.current!.slideTo(e.detail.value);
  };

  // A function to handle the slider changes
  const handleSlideChange = async (event: any) => {
    let index: number = 0;
    await event.target.getActiveIndex().then((value: any) => (index = value));
    setValue("" + index);
  };

  return (
    <IonPage>
      <PageHeader />
      <IonContent className="page-content">
        <IonHeader
          collapse="condense"
          className="page-content__header ion-hide"
        >
          <IonToolbar className="page-content__toolbar ion-text-center ion-margin-vertical">
            <IonTitle className="page-content__title">Home</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="flight-schedule ion-margin ion-text-center">
          <IonSegment
            className="flight-schedule__toggler"
            onIonChange={(e) => handleSegmentChange(e)}
            mode="ios"
            value={value}
          >
            <IonSegmentButton
              className="flight-schedule__toggler__btn flight-schedule__toggler__btn"
              indicator-background="primary"
              value="0"
            >
              <IonLabel>Departures</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              className="flight-schedule__toggler__btn"
              data-theme="arrivals"
              value="1"
            >
              <IonLabel>Arrivals</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </div>

        <IonCard className="card--page-container card">
          <IonCardContent className="card__content">
            <IonSlides
              pager={true}
              options={slideOpts}
              onIonSlideDidChange={(e) => handleSlideChange(e)}
              ref={slider}
            >
              <IonSlide className="flight-schedule">
                <IonList className="flight-schedule__list list">
                  <>
                    {loading ? (
                      <></>
                    ) : (
                      <>
                        {Object.keys(departuresDataGroup).map((date) => {
                          return (
                            <FlightScheduleGroup
                              data={departuresDataGroup[date]}
                              date={date}
                              flightType={"departures"}
                              key={`departures-${date}`}
                            ></FlightScheduleGroup>
                          );
                        })}
                      </>
                    )}
                  </>
                </IonList>
              </IonSlide>

              <IonSlide className="flight-schedule">
                <IonList className="flight-schedule__list list">
                  <>
                    {loading ? (
                      <IonLoading
                        isOpen={true}
                        message={"Loading Schedules..."}
                        duration={1000}
                      />
                    ) : (
                      <>
                        {Object.keys(arrivalsDataGroup).map((date) => {
                          return (
                            <FlightScheduleGroup
                              data={arrivalsDataGroup[date]}
                              date={date}
                              flightType={"arrivals"}
                              key={`arrivals-${date}`}
                            ></FlightScheduleGroup>
                          );
                        })}
                      </>
                    )}
                  </>
                </IonList>
              </IonSlide>
            </IonSlides>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
