import PageHeader from "../components/PageHeader";
import Moment from "react-moment";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonCardContent,
  IonLabel,
  IonList,
  IonLoading,
  IonItem,
  IonButton,
  IonText,
  IonImg,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core";
import { chevronDownCircleOutline } from "ionicons/icons";
import { getPublicNotices } from "../Api";
import { PublicNoticeItem } from "../interfaces/PublicNoticeItem";
import "./PublicNoticesPage.css";

const PublicNoticesPage: React.FC = () => {
  const [showLoading, setShowLoading] = useState(true);
  const [publicNoticesData, setPublicNoticesData] = useState<PublicNoticeItem[] | null>();
  const history = useHistory();

  useEffect(() => {
    setShowLoading(true);
    getPublicNotices().then((response: any) => {
      if (response.data) {
        if (response.data.records_total > 0) {
          setPublicNoticesData(response.data.records);
          setShowLoading(false);
        }
      }
    });
  }, []);

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getPublicNotices().then((response: any) => {
      if (response.data) {
        if (response.data.records_total > 0) {
          setPublicNoticesData(response.data.records);
          event.detail.complete();
        }
      }
    });
  }

  function handleIonItemClick(event: React.MouseEvent, data: PublicNoticeItem) {
    history.push({
      pathname: "/page/public-notices-details",
      state: {
        data: data,
      },
    });
  }

  return (
    <IonPage className="subpage--public-notices subpage">
      <PageHeader backButton={true} backButtonHref="/page/home" />

      <IonContent className="page-content">
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText="Pull to refresh"
          ></IonRefresherContent>
        </IonRefresher>

        <IonHeader className="page-content__header">
          <IonToolbar className="page-content__toolbar ion-text-center ion-margin-vertical">
            <IonTitle className="subpage__title page-content__title ion-text-center">
              Public Notices
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard className="card--page-container card">
          {publicNoticesData ? (
            <IonCardContent className="card__content">
              {publicNoticesData && (
                <IonList className="list--s-1 list">
                  {publicNoticesData.map((item, key) => {
                    return (
                      <IonItem
                        key={key}
                        className="list__item ion-no-padding"
                        onClick={(e) => {
                          handleIonItemClick(e, item);
                        }}
                      >
                        <IonText className="list__published-date ion-text-uppercase ion-no-margin">
                          <Moment
                            date={item.date_published}
                            format="dddd, MMMM D, YYYY"
                          />
                          <IonButton
                            className="list__btn"
                            href="/page/public-notices-details"
                          >
                            <IonImg src="/assets/arrow-right.svg" />
                          </IonButton>
                        </IonText>
                        <IonLabel className="list__title">
                          {item.title}
                        </IonLabel>
                      </IonItem>
                    );
                  })}
                </IonList>
              )}
            </IonCardContent>
          ) : (
            <IonLoading
              isOpen={showLoading}
              onDidDismiss={() => setShowLoading(false)}
              message={"Loading Public Notices.."}
            />
          )}
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default PublicNoticesPage;
