import React from "react";
import PageHeader from "../../components/PageHeader";
import {
  IonPage,
  IonToolbar,
  IonTitle,
  IonContent,
  IonText,
  IonIcon,
} from "@ionic/react";
import { checkmarkCircleSharp } from "ionicons/icons";
import "./SuccessPage.css";
import "../ContactUsPage.css";

const SuccessPage: React.FC = () => {
  return (
    <IonPage className="subpage--contact-us-success subpage--contact-us subpage">
      <PageHeader backButton={true} backButtonHref="/page/contact-us" />
      <IonContent>
        <div className="subpage__details ion-padding">
          <IonToolbar>
            <IonTitle color="primary">
              <h3 className="subpage__title ion-margin-bottom ion-text-center">
                <IonIcon icon={checkmarkCircleSharp} />
                Success!
              </h3>
            </IonTitle>
          </IonToolbar>

          <IonText className="ion-text-center">
            <h4 className="subpage__label">Thank you for contacting us!</h4>
            <p className="subpage__text ion-no-margin">
              You will receive a notification about your inquiry shortly.
            </p>
          </IonText>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SuccessPage;
