import PageHeader from "../components/PageHeader";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
} from "@ionic/react";
import { PublicNoticeItem } from "../interfaces/PublicNoticeItem";
import "./PublicNoticesDetailsPage.css";

interface DataProps {
  location: {
    state: {
      data: PublicNoticeItem;
    };
  };
}

/*
 * @TODO this page should not be accessible when no data or state were passed
 */
const PublicNoticesDetailsPage: React.FC<DataProps> = (props) => {
  const [data, setData] = useState<PublicNoticeItem>({
    id: 0,
    status: 0,
    title: "",
    slug: "",
    href: "",
    url: null,
    featured: false,
    date_start: null,
    date_end: null,
    date_published: "",
    excerpt: "",
    body: "",
    image: null,
    image_caption: null,
  });

  useEffect(() => {
    if (props.location.state.data) {
      setData(props.location.state.data);
    }
  }, [data]);

  return (
    <IonPage className="subpage--public-notices-details subpage">
      <PageHeader backButton={true} backButtonHref="/page/public-notices" />

      <IonContent className="page-content">
        <IonHeader className="page-content__header">
          <IonToolbar className="page-content__toolbar ion-text-center ion-margin-vertical">
            <IonTitle className="subpage__title page-content__title ion-text-center">
              Public Notices
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard className="card--article card--page-container card">
          <IonCardHeader className="card__header">
            <IonCardTitle className="card__title">{data.title}</IonCardTitle>
            <IonCardSubtitle className="card__published-date card__subtitle">
              <Moment date={data.date_published} format="dddd, MMMM D, YYYY" />
            </IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent className="card__content">
            <div dangerouslySetInnerHTML={{ __html: data.body }} />
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default PublicNoticesDetailsPage;
