import React from "react";
import { IonItemGroup, IonItemDivider, IonLabel } from "@ionic/react";
import FlightScheduleItem from "./FlightScheduleItem";
import { getDayLabels } from "../Utils";

const FlightScheduleGroup = (props: any) => {
  const { data, date, flightType } = props;
  const [dayLabel, dateLabel] = getDayLabels(date);

  return (
    <IonItemGroup
      className="flight-schedule__group"
      key={`schedule-group-${date}`}
    >
      <IonItemDivider
        className="flight-schedule__divider"
        data-theme={flightType}
      >
        <IonLabel>
          {dayLabel} <i>&middot;</i> {dateLabel}
        </IonLabel>
      </IonItemDivider>
      {data.map((item: any) => {
        return (
          <FlightScheduleItem data={item} key={item.id}></FlightScheduleItem>
        );
      })}
    </IonItemGroup>
  );
};

export default FlightScheduleGroup;
