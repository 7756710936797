import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonMenuButton,
  IonImg,
} from "@ionic/react";

interface PageHeaderProps {
  backButton?: boolean;
  backButtonText?: string;
  backButtonHref?: string;
}

const PageHeader: React.FC<PageHeaderProps> = (props) => {
  return (
    <React.Fragment>
      <IonHeader className="page-header">
        <IonToolbar className="page-header__toolbar">
          <IonButtons className="page-header__menu-btn" slot="start">
            {!props.backButton && (
              <IonMenuButton className="hamburger-menu" color="primary" />
            )}
            {props.backButton && (
              <IonBackButton
                color="primary"
                text=""
                {...(props.backButtonText && { text: props.backButtonText })}
                {...(props.backButtonHref && {
                  defaultHref: props.backButtonHref,
                })}
              />
            )}
          </IonButtons>

          <IonImg src="/assets/logo.svg" className="logo ion-align-center" />
        </IonToolbar>
      </IonHeader>
    </React.Fragment>
  );
};

export default PageHeader;
