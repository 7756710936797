import React from "react";
import { useHistory } from "react-router-dom";
import {
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonButton,
  IonNote,
} from "@ionic/react";

const FlightScheduleItem = (props: any) => {
  const { data } = props;
  const history = useHistory();
  const handleIonButtonClick = (event: React.MouseEvent, data: {}) => {
    history.push({
      pathname: "/page/flight-details",
      state: {
        data: data,
      },
    });
    event.preventDefault();
  };

  return (
    <IonItem className="flight-schedule__item ion-no-padding">
      <IonGrid className="ion-no-padding">
        <IonRow className="flight-schedule__row">
          <IonCol className="flight-schedule__col" size="12" size-xs="auto">
            <IonLabel className="ion-no-margin">
              <p className="flight-schedule__number-and-time ion-text-uppercase">
                <span className="flight-schedule__number">
                  {data.flight_no}
                </span>
                <span className="flight-schedule__separator"> / </span>
                <span className="flight-schedule__time">
                  {data.scheduled_time}
                </span>
              </p>
              <p className="flight-schedule__destination">
                {data.leg === "Arrival"
                  ? data.airport_name_origin
                  : data.airport_name_destination}
              </p>
            </IonLabel>
          </IonCol>
          <IonCol
            className="flight-schedule__col ion-align-self-center"
            size="auto"
          >
            {data.can_subscribe ? (
              <IonButton
                className="flight-schedule__btn btn--s-1 btn"
                onClick={(e) => {
                  handleIonButtonClick(e, data);
                }}
                size="default"
                fill="outline"
              >
                Notify Me
              </IonButton>
            ) : (
              <IonNote
                className={`${data.flight_status_class} flight-schedule__status`}
              >
                {data.remark}
              </IonNote>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default FlightScheduleItem;
