import axios from "axios";
import { IWCONFIG } from "./IwConfig";

// Use to authorize other API calls
const AuthorizeApi = axios.create({
  headers: {
    "Api-Sig": IWCONFIG.API_AUTHORIZE_APP_SIG,
    "App-Id": IWCONFIG.API_APP_ID,
  },
});

const getAuthorizeTokenFromApi = async () => {
  const response = await AuthorizeApi.post(IWCONFIG.API_AUTHORIZE_ENDPOINT);
  if (response.data) {
    return response.data.token;
  }
};

const asyncLocalStorage = {
  setItem: async function (key: string, value: any) {
    return Promise.resolve().then(function () {
      window.localStorage.setItem(key, JSON.stringify(value));
    });
  },
  getItem: async function (key: string) {
    return Promise.resolve().then(function () {
      let item = window.localStorage.getItem(key);
      item = item ? JSON.parse(item) : null;
      return item;
    });
  },
};

export const getAuthorizeToken = async (
  callback?: Function,
  errorCallback?: Function
) => {
  let key = "token";
  return asyncLocalStorage.getItem(key).then(async (token) => {
    if (token == null) {
      return getAuthorizeTokenFromApi()
        .then((token) => {
          // If there's no error while getting the token
          // token should not be undefined
          if (token) {
            asyncLocalStorage.setItem(key, token).then(async () => {
              return asyncLocalStorage.getItem(key).then(async (token) => {
                if (callback) {
                  callback?.apply(null, [token]);
                }
              });
            });
          }
        })
        .catch((error) => {
          if (errorCallback) {
            if (error.response) {
              if (error.response.data.message) {
                errorCallback?.apply(null, [
                  error.response.data.message,
                  error.response.status,
                ]);
              }
            } else if (error.message) {
              // Maybe network error?
              // CORS?
              // Invalid URL?
              errorCallback?.apply(null, [error.message, 598]);
            } else {
              errorCallback?.apply(null, ["Unexpected error", 500]);
            }
          }
        });
    }
    if (callback) {
      callback?.apply(null, [token]);
    }
  });
};

// At this point we know that a token was set
// by the App Authorization workflow
export const getStoredToken = () => {
  let token = window.localStorage.getItem("token");
  token = token ? JSON.parse(token) : null;
  return token;
};

export const Api = axios.create({
  headers: {
    Accept: "application/json",
  },
});

export const callApiGet = (endpoint: string) => {
  Api.defaults.headers.common["Authorization"] = getStoredToken();
  return Api.get(endpoint);
};

export const getFlightSchedules = () => {
  return callApiGet(IWCONFIG.API_GET_FLIGHT_SCHEDULES);
};

export const getPublicNotices = () => {
  return callApiGet(IWCONFIG.API_GET_PUBLIC_NOTICES);
};
