import moment from "moment";

export const getDayLabels = (date: string) => {
  const today = moment();
  const tomorrow = moment().add(1, "day");
  const dateLabel: string = moment(date).format("MMMM D, YYYY");
  let dayLabel: string = "";

  if (moment(date).isSame(today, "day")) {
    dayLabel = "Today";
  } else if (moment(date).isSame(tomorrow, "day")) {
    dayLabel = "Tomorrow";
  } else {
    dayLabel = "Within the week";
  }

  return [dayLabel, dateLabel];
};
