import React from "react";
import PageHeader from "../components/PageHeader";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard, 
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonRouterLink,
} from "@ionic/react";
import "./AdminAnnouncementsDetailsPage.css";

const AdminAnnouncementsDetailsPage: React.FC = () => {
  return (
    <IonPage className="subpage--admin-announcements-details subpage">
      <PageHeader backButton={true} backButtonHref="/page/admin-announcements" />

      <IonContent className="page-content">
        <IonHeader className="page-content__header">
          <IonToolbar className="page-content__toolbar ion-text-center ion-margin-vertical">
            <IonTitle className="subpage__title page-content__title ion-text-center">Admin Announcements</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard className="card--article card--page-container card">
          <IonCardHeader className="card__header">
            <IonCardTitle className="card__title">Airport to Kick Off 40th Anniversary with Proclamation, Tenant Parade &amp; WAVE</IonCardTitle>
            <IonCardSubtitle className="card__published-date card__subtitle">Monday, December 14, 2020</IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent className="card__content">
            <p>
              January 6, 2016 - The A.B. Won Pat International Airport Authority, Guam will be kicking off 
              their 40th Anniversary tomorrow, Friday, January 8, 2016 with a host of activities to set the 
              stage for their annual Aiport Week celebrations.
            </p>

            <p>
              The Honorable Lt. Governor Ray Tenorio will be signing a proclamation at the Airport's East 
              Ticket Lobby at 3:00pm to Proclaim Airport Week 2016 and commemorate the 40th Anniversary of 
              the Airport Authority as an autonomous agency of the Government of Guam. Airport Tenants and 
              aviation business partners will be on hand at the proclamation and all stakeholders will 
              march down to the parking lot along Route 10-A in a Tenant Parade. There, they will all wave 
              and share the Hafa Adai and 40th Anniversary celebratory spirit with passing motorist. 
              A barbeque cook-off between various renowed BBQ teams from the airport, airlines and tenant 
              will culminate this first day of activities for Airport Week 2016 Celebrating 40 Years of 
              Growth and Progress.
            </p>

            <p>More activities follow for the rest of the month with the following schedule.</p>

            <p>
              For a look back in time... check out the Timeline of the A.B. Won Pat International Airport Authority, Guam since 1976.
            </p>

            <IonRouterLink className="link" href="#">
              Download: Airport to Kick Off 40th Anniversary with Proclamation, Tenant Parade &amp; WAVE
            </IonRouterLink>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default AdminAnnouncementsDetailsPage;
