import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard, 
  IonCardContent,
  IonLabel,
  IonInput,
  IonTextarea,
  IonList,
  IonItem,
  IonButton,
  IonText,
  IonLoading,
} from "@ionic/react";
import "./ContactUsPage.css";

const ContactUsPage: React.FC = () => {
  const history = useHistory();

  const [showLoading, setShowLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");

  const successTimeout: number = 2000;
  const onSubmit = (data: any) => {
    console.log(data);
    setShowLoading(true);
    setFullName(data.full_name);
    setEmail(data.email);
    setOrganization(data.organization);
    setPhone(data.phone);
    setComment(data.comment);
    setTimeout(() => {
      history.push("/page/contact-us/success");
      setFullName("");
      setEmail("");
      setOrganization("");
      setPhone("");
      setComment("");
    }, successTimeout);
  };

  const validationMessages = {
    full_name: {
      required: {
        value: true,
        message: "Full Name is required.",
      },
    },
    email: {
      required: {
        value: true,
        message: "Email is required.",
      },
    },
    organization: {
      required: {
        value: true,
        message: "Organization is required.",
      },
    },
    phone: {
      required: {
        value: true,
        message: "Phone is required.",
      },
    },
    comment: {
      required: {
        value: true,
        message: "Comment is required.",
      },
    },
  };

  return (
    <IonPage className="subpage--contact-us subpage">
      <PageHeader backButton={true} backButtonHref="/page/home" />

      <IonContent className="page-content">
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
          duration={successTimeout}
        />

        <IonHeader className="page-content__header">
          <IonToolbar className="page-content__toolbar ion-text-center ion-margin-vertical">
            <IonTitle className="subpage__title page-content__title ion-text-center">Contact Us</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard className="card--page-container card">
          <IonCardContent className="card__content">

            <IonLabel className="card__label" color="dark">
              Leave us a message and we will get contact with you as soon as possible.
            </IonLabel>

            <form onSubmit={handleSubmit(onSubmit)}>
              <IonList
                className="contact-us-form ion-margin-horizontal ion-no-padding"
                lines="none"
              >
                <IonItem className="ion-margin-vertical">
                  <IonLabel className="contact-us-form__label" position="stacked">
                    Full Name
                  </IonLabel>
                  <IonInput
                    className={
                      errors.full_name
                        ? "contact-us-form__input contact-us-form__error-input"
                        : "contact-us-form__input"
                    }
                    name="full_name"
                    ref={register(validationMessages.full_name)}
                    value={fullName}
                  ></IonInput>
                  {errors.full_name && (
                    <IonText className="contact-us-form__error-text">
                      {errors.full_name.message}
                    </IonText>
                  )}
                </IonItem>

                <IonItem className="ion-margin-vertical">
                  <IonLabel className="contact-us-form__label" position="stacked">
                    Email
                  </IonLabel>
                  <IonInput
                    className={
                      errors.email
                        ? "contact-us-form__input contact-us-form__error-input"
                        : "contact-us-form__input"
                    }
                    type="email"
                    name="email"
                    inputmode="email"
                    ref={register(validationMessages.email)}
                    value={email}
                  ></IonInput>
                  {errors.email && (
                    <IonText className="contact-us-form__error-text">
                      {errors.email.message}
                    </IonText>
                  )}
                </IonItem>

                <IonItem className="ion-margin-vertical">
                  <IonLabel className="contact-us-form__label" position="stacked">
                    Organization
                  </IonLabel>
                  <IonInput
                    className={
                      errors.organization
                        ? "contact-us-form__input contact-us-form__error-input"
                        : "contact-us-form__input"
                    }
                    name="organization"
                    ref={register(validationMessages.organization)}
                    value={organization}
                  ></IonInput>
                  {errors.organization && (
                    <IonText className="contact-us-form__error-text">
                      {errors.organization.message}
                    </IonText>
                  )}
                </IonItem>

                <IonItem className="ion-margin-vertical">
                  <IonLabel className="contact-us-form__label" position="stacked">
                    Phone
                  </IonLabel>
                  <IonInput
                    className={
                      errors.phone
                        ? "contact-us-form__input contact-us-form__error-input"
                        : "contact-us-form__input"
                    }
                    name="phone"
                    ref={register(validationMessages.phone)}
                    value={phone}
                  ></IonInput>
                  {errors.phone && (
                    <IonText className="contact-us-form__error-text">
                      {errors.phone.message}
                    </IonText>
                  )}
                </IonItem>

                <IonItem className="ion-margin-vertical">
                  <IonLabel className="contact-us-form__label" position="stacked">
                    Comment
                  </IonLabel>
                  <IonTextarea
                    className={
                      errors.comment
                        ? "contact-us-form__input contact-us-form__error-input"
                        : "contact-us-form__input"
                    }
                    name="comment"
                    ref={register(validationMessages.comment)}
                    rows={6}
                    value={comment}
                  ></IonTextarea>
                  {errors.comment && (
                    <IonText className="contact-us-form__error-text">
                      {errors.comment.message}
                    </IonText>
                  )}
                </IonItem>

                <IonButton
                  className="contact-us-form__btn btn--s-2 btn"
                  href="#"
                  shape="round"
                  type="submit"
                  expand="block"
                >
                  Submit
                </IonButton>
              </IonList>
            </form>

          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default ContactUsPage;
