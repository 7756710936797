import React, { useState, useEffect } from "react";
import PageHeader from "../components/PageHeader";

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonCardContent,
  IonLabel,
  IonList,
  IonItem,
  IonItemGroup,
  IonItemDivider,
  IonButton,
  IonNote,
  IonActionSheet,
  IonAlert,
} from "@ionic/react";

import "./FlightDetailsPage.css";

interface DataProps {
  location: {
    state: {
      data: {};
    };
  };
}

interface FlightDetails {
  flight_no: string;
  day_label: string;
  date_label: string;
  airport_name_origin: string;
  airport_name_destination: string;
  theme: string;
  from_time: string;
  to_time: string;
}

/*
 * @TODO this page should not be accessible when no data or state were passed
 */
const FlightDetailsPage: React.FC<DataProps> = (props: any) => {
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [data, setData] = useState<FlightDetails>({
    flight_no: "",
    day_label: "",
    date_label: "",
    airport_name_origin: "",
    airport_name_destination: "",
    theme: "",
    from_time: "",
    to_time: "",
  });

  useEffect(() => {
    if (props.location.state && props.location.state.data) {
      let rawData = props.location.state.data;
      console.log(rawData);
      setData(rawData);
    }
  }, []);

  return (
    <IonPage className="subpage--flight-details subpage">
      <PageHeader backButton={true} backButtonHref="/page/home" />

      <IonContent className="page-content">
        <IonHeader className="page-content__header">
          <IonToolbar className="page-content__toolbar ion-text-center ion-margin-vertical">
            <IonTitle className="subpage__title page-content__title ion-text-center">
              Flight {data.flight_no}
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard className="card--page-container card">
          <IonCardContent className="card__content">
            <IonList className="flight-schedule--details flight-schedule list">
              <IonItemGroup>
                <IonItemDivider
                  className="flight-schedule__divider"
                  data-theme={data.theme}
                >
                  <IonLabel>
                    {data.day_label} <i>&middot;</i> {data.date_label}
                  </IonLabel>
                </IonItemDivider>

                <IonItem className="flight-schedule__item flight-schedule__item--from ion-no-padding">
                  <IonLabel className="ion-no-margin">
                    <p className="flight-schedule__from-label">From</p>
                    <p className="flight-schedule__from-location">
                      {data.airport_name_origin}
                    </p>
                  </IonLabel>
                  <IonNote className="flight-schedule__time" slot="end">
                    {data.from_time}
                  </IonNote>
                </IonItem>

                <IonItem className="flight-schedule__item flight-schedule__item--to ion-no-padding">
                  <IonLabel className="ion-no-margin">
                    <p className="flight-schedule__from-label">To</p>
                    <p className="flight-schedule__from-location">
                      {data.airport_name_destination}
                    </p>
                  </IonLabel>
                  <IonNote className="flight-schedule__time" slot="end">
                    {data.to_time}
                  </IonNote>
                </IonItem>
              </IonItemGroup>
            </IonList>

            <div className="ion-text-center notify-btn">
              <IonButton
                onClick={() => setShowActionSheet(true)}
                className="btn btn--s-1 ion-padding-horizontal"
                fill="outline"
              >
                Notify me about this flight
              </IonButton>
            </div>
          </IonCardContent>
        </IonCard>
      </IonContent>

      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        cssClass="action-sheet--s-2 action-sheet--s-1 action-sheet"
        header={`Enable Notifications for Flight ${data.flight_no}`}
        sub-header="You will receive push notifications on departure delays, arrivals, cancellations, and diversions."
        buttons={[
          {
            text: "Enable",
            role: "destructive",
            cssClass: "action-sheet-btn action-sheet-btn--logout btn--s-2 btn",
            handler: () => {
              return setShowAlert(true);
            },
          },
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "action-sheet-btn action-sheet-btn--cancel btn--s-3 btn",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
        ]}
      ></IonActionSheet>

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass="alert alert--s-1 alert--with-icon icon-lobby-waiting"
        header={`You subscribed to notifications for flight ${data.flight_no}`}
        // subHeader={'Subtitle'}
        message={
          "You will receive push notifications on departure delays, arrivals, cancellations, and diversions."
        }
        buttons={[
          {
            text: "Dismiss",
            role: "cancel",
            cssClass: "btn btn--s-2",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
        ]}
      />
    </IonPage>
  );
};

export default FlightDetailsPage;
