import React from "react";
import PageHeader from "../components/PageHeader";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard, 
  IonCardContent,
  IonLabel,
  IonList,
  IonItem,
  IonButton,
  IonText,
  IonImg,
} from "@ionic/react";
import "./AdminAnnouncementsPage.css";

const AdminAnnouncementsPage: React.FC = () => {
  return (
    <IonPage className="subpage--admin-announcements subpage">
      <PageHeader backButton={true} backButtonHref="/page/home" />

      <IonContent className="page-content">
        <IonHeader className="page-content__header">
          <IonToolbar className="page-content__toolbar ion-text-center ion-margin-vertical">
            <IonTitle className="subpage__title page-content__title ion-text-center">Admin Announcements</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard className="card--page-container card">
          <IonCardContent className="card__content">

            <IonList className="list--s-1 list">

              <IonItem href="/page/admin-announcements-details" className="list__item ion-no-padding">
                <IonText className="list__published-date ion-text-uppercase ion-no-margin">
                  Monday, December 14, 2020
                  <IonButton className="list__btn" href="/page/admin-announcements-details">
                    <IonImg src="/assets/arrow-right.svg" />
                  </IonButton>
                </IonText>
                <IonLabel className="list__title">Airport to Kick Off 40th Anniversary with Proclamation, Tenant Parade &amp; WAVE</IonLabel>
              </IonItem>

              <IonItem href="/page/admin-announcements-details" className="list__item ion-no-padding">
                <IonText className="list__published-date ion-text-uppercase ion-no-margin">
                  Tuesday, December 08, 2020
                  <IonButton className="list__btn" href="/page/admin-announcements-details">
                    <IonImg src="/assets/arrow-right.svg" />
                  </IonButton>
                </IonText>
                <IonLabel className="list__title">Guam Airport &amp; Partners Donate to Local Austism Organizations</IonLabel>
              </IonItem>

              <IonItem href="/page/admin-announcements-details" className="list__item ion-no-padding">
                <IonText className="list__published-date ion-text-uppercase ion-no-margin">
                  Thursday, November 12, 2020
                  <IonButton className="list__btn" href="/page/admin-announcements-details">
                    <IonImg src="/assets/arrow-right.svg" />
                  </IonButton>
                </IonText>
                <IonLabel className="list__title">Main Terminal Chiller 1 Under Preventive Maintenance</IonLabel>
              </IonItem>

              <IonItem href="/page/admin-announcements-details" className="list__item ion-no-padding">
                <IonText className="list__published-date ion-text-uppercase ion-no-margin">
                  Tuesday, September 01, 2020
                  <IonButton className="list__btn" href="/page/admin-announcements-details">
                    <IonImg src="/assets/arrow-right.svg" />
                  </IonButton>
                </IonText>
                <IonLabel className="list__title">Air Conditioner Preventive Maintenance Continues</IonLabel>
              </IonItem>

            </IonList>

          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default AdminAnnouncementsPage;
